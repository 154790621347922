import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import Annotator from '../../pages/Annotator/Annotator'
import Dashboard from '../../pages/Dashboard/Dashboard'
import List from '../../pages/List/List'
import Page from '../../pages/Page/Page'
import User from '../../pages/User/User'
import { redirectTo } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { clearStatuses } from '../../store/user/actions'
import NotLoggedRouter from '../NotLoggedRouter/NotLoggedRouter'

const MainRouter = (): ReactElement => {
  const dispatch = useDispatch()
  const isLogged = useSelector((state: GlobalState) => state.user.isLogged)
  const goTo = useSelector((state: GlobalState) => state.application.goTo)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    dispatch(clearStatuses())
    dispatch(redirectTo(''))
  }, [location, dispatch])

  useEffect(() => {
    if (goTo) {
      history.push(goTo)
    }
  }, [goTo, history])

  return !isLogged ? (
    <NotLoggedRouter />
  ) : (
    <Page>
      <Switch>
        <PrivateRoute exact path="/dashboard" isLogged>
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute exact path="/annotator" isLogged>
          <List />
        </PrivateRoute>
        <PrivateRoute exact path="/annotator/:id" isLogged>
          <Annotator />
        </PrivateRoute>
        <PrivateRoute exact path="/user" isLogged>
          <User />
        </PrivateRoute>
        <Redirect to="/dashboard" />
      </Switch>
    </Page>
  )
}

export default MainRouter
