import { User } from '../types'
import { getFromLocalStorage } from './localStorageManager'

export const readToken = (): string | null => {
  const access = getFromLocalStorage('access', '')
  return access.length > 0 ? access : null
}

export const readUserFromToken = (): User | null => {
  const token = readToken()
  if (token) {
    try {
      const user = JSON.parse(atob(token.split('.')[1]))
      return user.user_details
    } catch {
      return null
    }
  }
  return null
}
