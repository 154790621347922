import { ThunkDispatch } from 'redux-thunk'

import {
  ApplicationState,
  ApplicationTypes,
  GO_TO,
  SET_FROM_DATE,
  SET_PAGE,
  SET_TAB,
  SET_TO_DATE,
  SET_UUID_SEARCH,
  TOGGLE_DRAWER,
} from './types'

const toggleDrawer = () => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: TOGGLE_DRAWER,
    })
  }
}
const setTab = (tab: number) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SET_TAB,
      payload: {
        tab,
      },
    })
  }
}
const setPage = (page: number) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SET_PAGE,
      payload: {
        page,
      },
    })
  }
}
const setFromDate = (fromDate: Date | null) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SET_FROM_DATE,
      payload: {
        fromDate,
      },
    })
  }
}
const setToDate = (toDate: Date | null) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SET_TO_DATE,
      payload: {
        toDate,
      },
    })
  }
}
const setUuidSearch = (uuidSearch: string) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SET_UUID_SEARCH,
      payload: {
        uuidSearch,
      },
    })
  }
}

const redirectTo = (goto: string) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: GO_TO,
      payload: {
        goto: goto.length > 0 ? goto : null,
      },
    })
  }
}

export { redirectTo, setFromDate, setPage, setTab, setToDate, setUuidSearch, toggleDrawer }
