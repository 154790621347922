import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HeaderMenu from '../../components/HeaderMenu/HeaderMenu'
import I18N from '../../components/I18N/I18N'
import { toggleDrawer } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'

const drawerWidth = 240

const useStyles = makeStyles((t: Theme) => ({
  appBar: {
    backgroundColor: t.palette.primary.dark,
    zIndex: t.zIndex.drawer + 1,
    transition: t.transitions.create(['width', 'margin'], {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: t.transitions.create(['width', 'margin'], {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}))

const Header = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)
  const open = useSelector((state: GlobalState) => state.application.isDrawerOpened)

  return (
    <AppBar position="absolute" className={classNames(classes.appBar, open && classes.appBarShift)} component="header">
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(toggleDrawer())}
          className={classNames(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
          <I18N text="Odocap" />
        </Typography>
        <HeaderMenu />
      </Toolbar>
    </AppBar>
  )
}

export default Header
