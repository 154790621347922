import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const useStyles = makeStyles((t: Theme) => ({
  cookieBar: {
    minHeight: '3rem',
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    zIndex: t.zIndex.drawer + 2,
    padding: t.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cookieButton: {
    marginLeft: t.spacing(2),
  },
  link: {
    color: t.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}))

const CookieBar = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Box boxShadow={3} bgcolor="background.paper" className={classes.cookieBar}>
      <Typography variant="body1">
        Nam sodales quam neque, id facilisis enim porttitor sit amet. Pellentesque quis{' '}
        <span className={classes.link}>Link to privacy policy</span>
      </Typography>
      <Button variant="contained" color="primary" className={classes.cookieButton}>
        Pointless button
      </Button>
    </Box>
  )
}

export default CookieBar
