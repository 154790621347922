import {
  ApplicationState,
  ApplicationTypes,
  GO_TO,
  SET_FROM_DATE,
  SET_PAGE,
  SET_TAB,
  SET_TO_DATE,
  SET_UUID_SEARCH,
  TOGGLE_DRAWER,
} from './types'

export const initialState: ApplicationState = {
  isDrawerOpened: false,
  uuidSearch: '',
  page: 1,
  tab: 0,
  fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  toDate: new Date(),
  goTo: null,
}

const applicationReducer = (state = initialState, action: ApplicationTypes): ApplicationState => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        isDrawerOpened: !state.isDrawerOpened,
      }
    case SET_UUID_SEARCH: {
      return {
        ...state,
        page: 1,
        uuidSearch: action.payload.uuidSearch,
      }
    }
    case SET_TAB: {
      return {
        ...state,
        page: 1,
        tab: action.payload.tab,
      }
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload.page,
      }
    }
    case SET_FROM_DATE: {
      return {
        ...state,
        page: 1,
        fromDate: action.payload.fromDate,
      }
    }
    case SET_TO_DATE: {
      return {
        ...state,
        page: 1,
        toDate: action.payload.toDate,
      }
    }
    case GO_TO: {
      return {
        ...state,
        goTo: action.payload.goto,
      }
    }
    default:
      return state
  }
}

export default applicationReducer
