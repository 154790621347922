import React from 'react'

import { FAIL_REASON_TEXTS, FailReason, JobStatus } from '../types'

// eslint-disable-next-line import/prefer-default-export
export const statusForJob = (status: string, failReason: FailReason | null | undefined) => (
  <>
    {status}
    {status === JobStatus.success && failReason && ` - ${FAIL_REASON_TEXTS[failReason]}`}
  </>
)
