import { getDisplayQuadrant, Quadrant, Rectangle, Size, Vector2 } from '@frontends/common'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import React, { useEffect, useRef, useState } from 'react'

import { useDrawResult } from '../../utils/useDrawResult'

interface Props {
  coordinates: Rectangle | null
  imageSize: Size
  save: React.Dispatch<any>
}

const useStyles = makeStyles((t: Theme) => ({
  imageCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'crosshair',
  },
}))

const ImageCanvas = (props: Props) => {
  const { coordinates, imageSize, save } = props

  const theme = useTheme()
  const classes = useStyles(theme)

  const [mouseDownPoint, setMouseDownPoint] = useState<Vector2>(new Vector2(0, 0))
  const [isDrawing, setIsDrawing] = useState<boolean>(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [canvasSize, setCanvasSize] = useState<Size>({ width: imageSize.width, height: imageSize.height })

  const [currentCoordinates, setCurrentCoordinates] = useState<Rectangle | null>(coordinates)

  useEffect(() => {
    setCurrentCoordinates(coordinates)
  }, [coordinates])

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current
      canvas.setAttribute('height', imageSize.height.toString())
      canvas.setAttribute('width', imageSize.width.toString())
      setCanvasSize(imageSize)
    }
  }, [imageSize])

  useDrawResult(currentCoordinates, canvasRef, imageSize, canvasSize)

  const mouseDown = (event: any): void => {
    if (!isDrawing) {
      setIsDrawing(true)
      const elemRect = event.target.getBoundingClientRect()
      setMouseDownPoint(
        new Vector2(
          (event.clientX - elemRect.left) / canvasSize.width,
          (event.clientY - elemRect.top) / canvasSize.height,
        ),
      )
      save(null)
    }
  }

  const mouseMove = (e: any): void => {
    if (isDrawing) {
      const elemRect = e.target.getBoundingClientRect()
      const mouseMovePoint = new Vector2(
        (e.clientX - elemRect.left) / canvasSize.width,
        (e.clientY - elemRect.top) / canvasSize.height,
      )

      switch (getDisplayQuadrant(mouseDownPoint, mouseMovePoint)) {
        case Quadrant.TopRight:
          setCurrentCoordinates([
            mouseDownPoint.x,
            mouseMovePoint.y,
            mouseMovePoint.x - mouseDownPoint.x,
            mouseDownPoint.y - mouseMovePoint.y,
          ])
          break
        case Quadrant.TopLeft:
          setCurrentCoordinates([
            mouseMovePoint.x,
            mouseMovePoint.y,
            mouseDownPoint.x - mouseMovePoint.x,
            mouseDownPoint.y - mouseMovePoint.y,
          ])
          break
        case Quadrant.BottomLeft:
          setCurrentCoordinates([
            mouseMovePoint.x,
            mouseDownPoint.y,
            mouseDownPoint.x - mouseMovePoint.x,
            mouseMovePoint.y - mouseDownPoint.y,
          ])
          break
        case Quadrant.BottomRight:
          setCurrentCoordinates([
            mouseDownPoint.x,
            mouseDownPoint.y,
            mouseMovePoint.x - mouseDownPoint.x,
            mouseMovePoint.y - mouseDownPoint.y,
          ])
          break
        default:
          break
      }
    }
  }

  const mouseUp = (_event: any): void => {
    if (isDrawing) {
      setIsDrawing(false)
      save(currentCoordinates)
    }
  }

  return (
    <canvas
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseMove={mouseMove}
      className={classes.imageCanvas}
      ref={canvasRef}
      width={canvasSize.width}
      height={canvasSize.height}
    />
  )
}

export default ImageCanvas
