import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import React from 'react'

import PageHeader from '../../containers/PageHeader/PageHeader'
import ChangePassword from './ChangePassword'
import UserDetail from './UserDetail'

const useStyles = makeStyles((t: Theme) => ({
  paper: {
    padding: t.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  divider: {
    marginTop: t.spacing(4),
    marginBottom: t.spacing(4),
  },
}))

const User = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      <PageHeader name="User" />
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Paper className={classes.paper}>
            <UserDetail />
            <Divider className={classes.divider} />
            <ChangePassword />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default User
