import { Report } from '../../types'

export interface ReportState {
  report: Report[] | null
  isLoadingReport: boolean
}

export const REPORT_LOAD_REPORT_STARTED = 'REPORT_LOAD_REPORT_STARTED'
export const REPORT_LOAD_REPORT_ERRORED = 'REPORT_LOAD_REPORT_ERRORED'
export const REPORT_LOAD_REPORT_FINISHED = 'REPORT_LOAD_REPORT_FINISHED'
export const REPORT_CLEAN = 'REPORT_CLEAN'

interface LoadReportStarted {
  type: typeof REPORT_LOAD_REPORT_STARTED
}

interface LoadReportErrored {
  type: typeof REPORT_LOAD_REPORT_ERRORED
}

interface LoadReportFinished {
  type: typeof REPORT_LOAD_REPORT_FINISHED
  payload: {
    report: Report[]
  }
}

interface CleanReport {
  type: typeof REPORT_CLEAN
}

export type ReportTypes = LoadReportStarted | LoadReportFinished | LoadReportErrored | CleanReport
