import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import I18N from '../../components/I18N/I18N'
import { GlobalState } from '../../store/configureStore'
import Chart from './Chart'

const useStyles = makeStyles((t: Theme) => ({
  paper: {
    padding: t.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    margin: t.spacing(1),
    minWidth: 120,
  },
  fixedHeight: {
    height: 360,
  },
}))

const DataChart = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const report = useSelector((state: GlobalState) => state.report.report)

  const [selectedYear, selectYear] = useState<number>(report && report.length > 0 ? report[0].year : 0)

  useEffect(() => selectYear(report && report.length > 0 ? report[0].year : 0), [report])

  return (
    report && (
      <Paper className={classNames(classes.paper, classes.fixedHeight)}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="year">
            <I18N text="Year" />
          </InputLabel>
          <Select
            native
            value={selectedYear}
            onChange={(event) => selectYear(Number(event.target.value))}
            inputProps={{
              name: 'year',
              id: 'year',
            }}
          >
            {[...new Set(report.map((r) => r.year))].map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </FormControl>
        {report.filter((r) => r.year === selectedYear).length > 0 && (
          <Chart data={report.filter((r) => r.year === selectedYear).reverse()} />
        )}
      </Paper>
    )
  )
}

export default DataChart
