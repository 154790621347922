import { Job, Jobs } from '../../types'

export interface JobsState {
  currentJob: Job | null
  jobs: Jobs | null
  isLoadingJob: boolean
  isSavingJob: boolean
  isLoadingJobs: boolean
  isWrongJobRequst: boolean
}

export const JOBS_LOAD_JOB_STARTED = 'REPORT_LOAD_JOB_STARTED'
export const JOBS_LOAD_JOB_ERRORED = 'REPORT_LOAD_JOB_ERRORED'
export const JOBS_LOAD_JOB_FINISHED = 'REPORT_LOAD_JOB_FINISHED'
export const JOBS_LOAD_JOBS_STARTED = 'REPORT_LOAD_JOBS_STARTED'
export const JOBS_LOAD_JOBS_ERRORED = 'REPORT_LOAD_JOBS_ERRORED'
export const JOBS_LOAD_JOBS_FINISHED = 'REPORT_LOAD_JOBS_FINISHED'
export const JOBS_SAVE_JOB_STARTED = 'REPORT_SAVE_JOB_STARTED'
export const JOBS_SAVE_JOB_ERRORED = 'REPORT_SAVE_JOB_ERRORED'
export const JOBS_SAVE_JOB_FINISHED = 'REPORT_SAVE_JOB_FINISHED'
export const JOBS_CLEAN = 'JOBS_CLEAN'

interface LoadJobsStarted {
  type: typeof JOBS_LOAD_JOBS_STARTED
}

interface LoadJobsErrored {
  type: typeof JOBS_LOAD_JOBS_ERRORED
}

interface LoadJobsFinished {
  type: typeof JOBS_LOAD_JOBS_FINISHED
  payload: {
    jobs: Jobs
  }
}

interface LoadJobStarted {
  type: typeof JOBS_LOAD_JOB_STARTED
}

interface LoadJobErrored {
  type: typeof JOBS_LOAD_JOB_ERRORED
  payload: {
    isWrongJobRequst: boolean
  }
}

interface LoadJobFinished {
  type: typeof JOBS_LOAD_JOB_FINISHED
  payload: {
    job: Job
  }
}

interface SaveJobStarted {
  type: typeof JOBS_SAVE_JOB_STARTED
}

interface SaveJobErrored {
  type: typeof JOBS_SAVE_JOB_ERRORED
}

interface SaveJobFinished {
  type: typeof JOBS_SAVE_JOB_FINISHED
}

interface CleanJobs {
  type: typeof JOBS_CLEAN
}

export type JobsTypes =
  | LoadJobsStarted
  | LoadJobsFinished
  | LoadJobsErrored
  | LoadJobStarted
  | LoadJobErrored
  | LoadJobFinished
  | SaveJobStarted
  | SaveJobErrored
  | SaveJobFinished
  | CleanJobs
