import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import classNames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { toggleDrawer } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { i18n } from '../../utils/i18n'

const drawerWidth = 240

const useStyles = makeStyles((t: Theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: t.transitions.create('width', {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: t.transitions.create('width', {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.leavingScreen,
    }),
    width: t.spacing(7),
    [t.breakpoints.up('sm')]: {
      width: t.spacing(9),
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...t.mixins.toolbar,
  },
}))

const Menu = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const open = useSelector((state: GlobalState) => state.application.isDrawerOpened)

  const dispatch = useDispatch()

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => dispatch(toggleDrawer())}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <div>
          <ListItem button key="dashboard" component={NavLink} to="/dashboard">
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary={i18n('Dashboard')} />
          </ListItem>
          <ListItem button key="annotator" component={NavLink} to="/annotator">
            <ListItemIcon>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText primary={i18n('Annotator')} />
          </ListItem>
        </div>
      </List>
      <Divider />
    </Drawer>
  )
}

export default Menu
