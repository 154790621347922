import { useTheme } from '@material-ui/core/styles'
import React from 'react'
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { Report } from '../../types'
import { emptyData } from '../../utils/emptyData'
import { i18n } from '../../utils/i18n'

interface Props {
  data: Report[]
}

const Chart = (props: Props) => {
  const theme = useTheme()
  const formatter = new Intl.DateTimeFormat('en-Gb', { month: 'long' })
  const { data } = props

  const arr = data.concat(emptyData)

  return (
    <ResponsiveContainer>
      <LineChart
        data={arr.filter((b, i) => arr.findIndex((a) => a.month === b.month) === i).sort((a, b) => a.month - b.month)}
        margin={{
          top: theme.spacing(2),
          right: theme.spacing(2),
          bottom: theme.spacing(0),
          left: theme.spacing(3),
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" stroke={theme.palette.text.secondary} />
        <YAxis stroke={theme.palette.text.secondary}>
          <Label angle={270} position="left" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
            {i18n('Total')}
          </Label>
        </YAxis>
        <Legend formatter={(_v, _n, _p) => [i18n('Total for month')]} />
        <Tooltip
          formatter={(value, _n, _p) => [value, i18n('Total')]}
          labelFormatter={(p) => formatter.format(new Date(2020, Number(p) - 1))}
        />
        <Line type="monotone" dataKey="total" stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Chart
