import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import reduxThunk from 'redux-thunk'

import applicationReducer from './application/reducer'
import { ApplicationState } from './application/types'
import jobsReducer from './jobs/reducer'
import { JobsState } from './jobs/types'
import reportReducer from './report/reducer'
import { ReportState } from './report/types'
import userReducer from './user/reducer'
import { UserState } from './user/types'

export function configureStore() {
  const rootReducer = combineReducers({
    report: reportReducer,
    user: userReducer,
    application: applicationReducer,
    jobs: jobsReducer,
  })
  // @ts-ignore
  return createStore(
    rootReducer,
    {},
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          applyMiddleware(reduxThunk),
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }),
        )
      : applyMiddleware(reduxThunk),
  )
}

export interface GlobalState {
  report: ReportState
  user: UserState
  application: ApplicationState
  jobs: JobsState
}
