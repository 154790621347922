export const saveToLocalStorage = (key: string, value: string): void => localStorage.setItem(key, value)

export const getFromLocalStorage = (key: string, defaultValue: string): string => {
  const item = localStorage.getItem(key)
  if (item) {
    return item
  }
  saveToLocalStorage(key, defaultValue)
  return defaultValue
}
