import { createStyles, Theme, withStyles } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'

const StyledTableRow = withStyles((t: Theme) =>
  createStyles({
    root: {
      height: '50px',
      '&:nth-of-type(even)': {
        backgroundColor: t.palette.action.hover,
      },
    },
  }),
)(TableRow)

export default StyledTableRow
