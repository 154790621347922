import { ThunkDispatch } from 'redux-thunk'

import axios from '../../utils/configuredAxios'
import { readToken } from '../../utils/readToken'
import { getNewAccessToken } from '../user/actions'
import {
  REPORT_CLEAN,
  REPORT_LOAD_REPORT_ERRORED,
  REPORT_LOAD_REPORT_FINISHED,
  REPORT_LOAD_REPORT_STARTED,
  ReportState,
  ReportTypes,
} from './types'

const getReport = () => {
  return async (dispatch: ThunkDispatch<ReportState, null, ReportTypes>) => {
    dispatch({
      type: REPORT_LOAD_REPORT_STARTED,
    })
    try {
      const reportRequest = await axios.get('/api/f/report/', {
        headers: {
          Authorization: `Bearer ${readToken()}`,
        },
      })
      dispatch({
        type: REPORT_LOAD_REPORT_FINISHED,
        payload: {
          report: reportRequest.data,
        },
      })
    } catch (e) {
      if (e.response && e.response.status === 401) {
        dispatch(getNewAccessToken())
      }
      dispatch({
        type: REPORT_LOAD_REPORT_ERRORED,
      })
    }
  }
}

const cleanReport = () => {
  return async (dispatch: ThunkDispatch<ReportState, null, ReportTypes>) => {
    dispatch({
      type: REPORT_CLEAN,
    })
  }
}

export { cleanReport, getReport }
