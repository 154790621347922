import React, { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import MainRouter from './router/MainRouter/MainRouter'
import { configureStore } from './store/configureStore'

const App = (): ReactElement => (
  <Provider store={configureStore()}>
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  </Provider>
)

export default App
