import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface PrivateRouteProps extends RouteProps {
  children: any
  isLogged: boolean
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { children, isLogged, ...rest } = props

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(routeProps) =>
        isLogged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
