import { Report } from '../types'

// eslint-disable-next-line import/prefer-default-export
export const emptyData = [
  { year: 1970, month: 1, total: undefined },
  { year: 1970, month: 2, total: undefined },
  { year: 1970, month: 3, total: undefined },
  { year: 1970, month: 4, total: undefined },
  { year: 1970, month: 5, total: undefined },
  { year: 1970, month: 6, total: undefined },
  { year: 1970, month: 7, total: undefined },
  { year: 1970, month: 8, total: undefined },
  { year: 1970, month: 9, total: undefined },
  { year: 1970, month: 10, total: undefined },
  { year: 1970, month: 11, total: undefined },
  { year: 1970, month: 12, total: undefined },
] as Report[]
