import { createStyles, Theme, withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'

const StyledTableCell = withStyles((t: Theme) =>
  createStyles({
    head: {
      backgroundColor: t.palette.primary.dark,
      color: t.palette.common.white,
      fontWeight: 700,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)

export default StyledTableCell
