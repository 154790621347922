import { emptyStatusMessage } from '@frontends/account'

import { getFromLocalStorage } from '../../utils/localStorageManager'
import { readUserFromToken } from '../../utils/readToken'
import {
  USER_CHANGE_PASSWORD_ERRORED,
  USER_CHANGE_PASSWORD_FINISHED,
  USER_CHANGE_PASSWORD_STARTED,
  USER_CLEAR_STATUSES,
  USER_FORGOTTEN_PASSWORD_ERRORED,
  USER_FORGOTTEN_PASSWORD_FINISHED,
  USER_FORGOTTEN_PASSWORD_STARTED,
  USER_LOGIN_ERRORED,
  USER_LOGIN_FINISHED,
  USER_LOGIN_STARTED,
  USER_LOGOUT,
  USER_REFRESH_TOKEN_ERRORED,
  USER_REFRESH_TOKEN_FINISHED,
  USER_REFRESH_TOKEN_STARTED,
  USER_RESET_PASSWORD_ERRORED,
  USER_RESET_PASSWORD_FINISHED,
  USER_RESET_PASSWORD_STARTED,
  UserState,
  UserTypes,
} from './types'

export const initialState: UserState = {
  user: readUserFromToken(),
  isLogging: false,
  isLogged: getFromLocalStorage('access', '').length > 0,
  isRefreshingToken: false,
  statusMessages: {
    login: emptyStatusMessage,
    forgotten: emptyStatusMessage,
    changePassword: emptyStatusMessage,
    reset: emptyStatusMessage,
  },
}

const userReducer = (state = initialState, action: UserTypes): UserState => {
  switch (action.type) {
    case USER_LOGIN_STARTED:
      return {
        ...state,
        isLogging: true,
        statusMessages: { ...state.statusMessages, login: emptyStatusMessage },
      }
    case USER_LOGIN_ERRORED:
      return {
        ...state,
        isLogging: false,
        statusMessages: { ...state.statusMessages, login: { error: action.payload.error, success: '' } },
      }
    case USER_LOGIN_FINISHED:
      return {
        ...state,
        user: action.payload.user,
        isLogged: true,
        isLogging: false,
        statusMessages: { ...state.statusMessages, login: emptyStatusMessage },
      }
    case USER_LOGOUT:
      return {
        ...state,
        isLogged: false,
        user: null,
      }
    case USER_FORGOTTEN_PASSWORD_STARTED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, forgotten: emptyStatusMessage },
      }
    case USER_FORGOTTEN_PASSWORD_FINISHED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, forgotten: { success: action.payload.success, error: null } },
      }
    case USER_FORGOTTEN_PASSWORD_ERRORED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, forgotten: { error: action.payload.error, success: '' } },
      }
    case USER_RESET_PASSWORD_STARTED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, reset: emptyStatusMessage },
      }
    case USER_RESET_PASSWORD_FINISHED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, reset: { success: action.payload.success, error: null } },
      }
    case USER_RESET_PASSWORD_ERRORED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, reset: { error: action.payload.error, success: '' } },
      }
    case USER_CHANGE_PASSWORD_STARTED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, changePassword: emptyStatusMessage },
      }
    case USER_CHANGE_PASSWORD_FINISHED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, changePassword: { success: action.payload.success, error: null } },
      }
    case USER_CHANGE_PASSWORD_ERRORED:
      return {
        ...state,
        statusMessages: { ...state.statusMessages, changePassword: { error: action.payload.error, success: '' } },
      }
    case USER_CLEAR_STATUSES:
      return {
        ...state,
        statusMessages: {
          ...state.statusMessages,
          login: emptyStatusMessage,
          forgotten: emptyStatusMessage,
          changePassword: emptyStatusMessage,
          reset: emptyStatusMessage,
        },
      }
    case USER_REFRESH_TOKEN_STARTED:
      return {
        ...state,
        isRefreshingToken: true,
      }
    case USER_REFRESH_TOKEN_FINISHED:
      return {
        ...state,
        isRefreshingToken: false,
      }
    case USER_REFRESH_TOKEN_ERRORED:
      return {
        ...state,
        isRefreshingToken: false,
      }
    default:
      return state
  }
}

export default userReducer
