/* eslint-disable camelcase */
import DateFnsUtils from '@date-io/date-fns'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'
import Pagination from '@material-ui/lab/Pagination'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import classNames from 'classnames'
import enLocale from 'date-fns/locale/en-GB'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import I18N from '../../components/I18N/I18N'
import StyledTableCell from '../../components/StyledTableCell/StyledTableCell'
import StyledTableRow from '../../components/StyledTableRow/StyledTableRow'
import PageHeader from '../../containers/PageHeader/PageHeader'
import { setFromDate, setPage, setTab, setToDate, setUuidSearch } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { getJobs } from '../../store/jobs/actions'
import { Job, JobStatus, JobTypes } from '../../types'
import { confidenceInPercentage, dateFormatter } from '../../utils/formatters'
import { i18n } from '../../utils/i18n'
import { statusForJob } from '../../utils/statusForJob'
import { useDebounce } from '../../utils/useDebounce'

const useStyles = makeStyles((t: Theme) => ({
  container: {
    paddingTop: t.spacing(4),
    paddingBottom: t.spacing(4),
  },
  monospace: {
    fontFamily: 'monospace',
  },
  tooltip: {
    marginLeft: t.spacing(1),
  },
  greenButton: {
    background: t.palette.success.main,
    color: t.palette.common.white,
    '&:hover': {
      background: t.palette.success.dark,
    },
  },
  redButton: {
    background: t.palette.error.main,
    color: t.palette.common.white,
    '&:hover': {
      background: t.palette.error.dark,
    },
  },
  menu: {
    marginBottom: '2rem',
  },
  error: {
    color: t.palette.error.main,
  },
  th: {
    display: 'flex',
  },
}))

const List = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()

  const isRefreshingToken = useSelector((state: GlobalState) => state.user.isRefreshingToken)
  const isLoadingJobs = useSelector((state: GlobalState) => state.jobs.isLoadingJobs)
  const jobs = useSelector((state: GlobalState) => state.jobs.jobs)
  const tab = useSelector((state: GlobalState) => state.application.tab)
  const page = useSelector((state: GlobalState) => state.application.page)
  const fromDate = useSelector((state: GlobalState) => state.application.fromDate)
  const toDate = useSelector((state: GlobalState) => state.application.toDate)
  const uuidSearch = useSelector((state: GlobalState) => state.application.uuidSearch)

  const [uuid, setUuid] = useState<string>('')
  const debouncedUuidSearch = useDebounce(uuid, 200)
  useEffect(() => {
    dispatch(setUuidSearch(debouncedUuidSearch))
  }, [debouncedUuidSearch, dispatch])

  const handlePageChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    if (!isLoadingJobs) {
      dispatch(setPage(newValue))
    }
  }

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    if (!isLoadingJobs) {
      dispatch(setTab(newValue))
    }
  }

  useEffect(() => {
    if (!isRefreshingToken) {
      dispatch(getJobs(page, JobTypes[tab], fromDate, toDate, uuidSearch))
    }
  }, [dispatch, page, tab, isRefreshingToken, fromDate, toDate, uuidSearch])

  return (
    <>
      <PageHeader name="Annotator Job List" />
      <Paper className={classes.menu}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          disabled={isLoadingJobs}
        >
          <Tab label={<I18N text="Odometer" />} />
          {false && <Tab label={<I18N text="Licence plate" />} />}
          {false && <Tab label={<I18N text="VIN" />} />}
        </Tabs>
      </Paper>
      <TextField
        id="uuid"
        label={i18n('Job ID')}
        type="text"
        value={uuid}
        onChange={(event) => setUuid(event.target.value)}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <DateTimePicker value={fromDate} onChange={(date) => dispatch(setFromDate(date))} label={i18n('Date from')} />
        <DateTimePicker value={toDate} onChange={(date) => dispatch(setToDate(date))} label={i18n('Date to')} />
      </MuiPickersUtilsProvider>
      {isLoadingJobs ? (
        <CircularProgress />
      ) : (
        <>
          {jobs && jobs.results && (
            <>
              <Pagination
                count={Math.ceil(jobs.count / 20)}
                color="primary"
                page={page}
                onChange={handlePageChange}
                disabled={isLoadingJobs}
              />
              <TableContainer component={Paper}>
                <Table size="small" aria-label="jobs table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        <I18N text="Job ID" />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <I18N text="Date" />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <I18N text="Status" />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <I18N text="Confidence" />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <I18N text="Value" />
                      </StyledTableCell>
                      <StyledTableCell align="left" className={classes.th}>
                        <I18N text="Annotation" />
                        <Tooltip
                          className={classes.tooltip}
                          title={
                            <>
                              <b>Red</b> - annotation not saved yet.
                              <br />
                              <b>Green</b> - annotation saved.
                            </>
                          }
                        >
                          <HelpIcon />
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobs.results.map((row: Job) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell className={classes.monospace} align="left">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell className={classes.monospace} align="left">
                          {dateFormatter.format(new Date(row.date_created))}
                        </StyledTableCell>
                        <StyledTableCell className={classes.monospace} align="left">
                          {statusForJob(row.status, row.result?.fail_reason)}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classNames(
                            classes.monospace,
                            row.status === JobStatus.success && row.result.confidence < 0.7 ? classes.error : '',
                          )}
                          align="right"
                        >
                          {row.status === JobStatus.success && confidenceInPercentage(row.result.confidence)}
                        </StyledTableCell>
                        <StyledTableCell align="right" className={classes.monospace}>
                          {row.status === JobStatus.success && row.result.value}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.status === JobStatus.success && (
                            <Button
                              size="small"
                              variant="contained"
                              className={classNames(row.annotation_submitted ? classes.greenButton : classes.redButton)}
                              type="button"
                              component={NavLink}
                              to={`/annotator/${row.id}`}
                            >
                              <I18N text="Open" />
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={Math.ceil(jobs.count / 20)}
                color="primary"
                page={page}
                onChange={handlePageChange}
                disabled={isLoadingJobs}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default List
