import { Size } from '@frontends/common'
import React, { useEffect } from 'react'

const getRenderedSize = (canvasSize: Size, imageSize: Size) => {
  const imageRatio = imageSize.width / imageSize.height
  const canvasRatio = canvasSize.width / canvasSize.height
  if (imageRatio > canvasRatio) {
    return { width: canvasSize.width, height: canvasSize.width / imageRatio }
  }
  return { width: canvasSize.height * imageRatio, height: canvasSize.height }
}

// eslint-disable-next-line import/prefer-default-export
export const useDrawResult = (
  coordinates: [number, number, number, number] | null,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  naturalSize: Size,
  canvasSize: Size,
) => {
  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current
      const renderedSize = getRenderedSize(canvasSize, naturalSize)

      if (coordinates) {
        const x = coordinates[0] * renderedSize.width + (canvasSize.width - renderedSize.width) / 2
        const y = coordinates[1] * renderedSize.height + (canvasSize.height - renderedSize.height) / 2
        const w = coordinates[2] * renderedSize.width
        const h = coordinates[3] * renderedSize.height

        const ctx = canvas.getContext('2d')
        ctx!.clearRect(0, 0, canvas.width, canvas.height)
        ctx!.beginPath()

        ctx!.rect(x, y, w, h)
        ctx!.strokeStyle = '#003471'
        ctx!.lineWidth = 2
        ctx!.stroke()

        ctx!.beginPath()
        ctx!.rect(x - 2, y - 2, w + 4, h + 4)
        ctx!.strokeStyle = '#FFFFFF'
        ctx!.lineWidth = 2
        ctx!.stroke()
      }
    }
  }, [naturalSize, canvasRef, canvasSize, coordinates])
}
