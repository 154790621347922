import { ThemeProvider } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'

import CookieBar from '../../components/CookieBar/CookieBar'
import Footer from '../../containers/Footer/Footer'
import Header from '../../containers/Header/Header'
import Menu from '../../containers/Menu/Menu'
import { theme } from '../../utils/theme'

interface Props {
  children: ReactElement
}

const useStyles = makeStyles((t: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    minHeight: '100vh',
  },
  appBarSpacer: t.mixins.toolbar,
  container: {
    paddingTop: t.spacing(4),
    paddingBottom: t.spacing(4),
  },
}))

const Page = (props: Props) => {
  const classes = useStyles(theme)

  const { children } = props

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <Menu />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            {children}
          </Container>
          <Footer />
        </main>
      </div>
      {false && <CookieBar />}
    </ThemeProvider>
  )
}

export default Page
