import {
  REPORT_CLEAN,
  REPORT_LOAD_REPORT_ERRORED,
  REPORT_LOAD_REPORT_FINISHED,
  REPORT_LOAD_REPORT_STARTED,
  ReportState,
  ReportTypes,
} from './types'

export const initialState: ReportState = {
  report: null,
  isLoadingReport: false,
}

const reportReducer = (state = initialState, action: ReportTypes): ReportState => {
  switch (action.type) {
    case REPORT_LOAD_REPORT_STARTED:
      return {
        ...state,
        isLoadingReport: true,
      }
    case REPORT_LOAD_REPORT_FINISHED:
      return {
        ...state,
        report: action.payload.report,
        isLoadingReport: false,
      }
    case REPORT_LOAD_REPORT_ERRORED:
      return {
        ...state,
        isLoadingReport: false,
      }
    case REPORT_CLEAN:
      return {
        ...state,
        report: null,
      }
    default:
      return state
  }
}

export default reportReducer
