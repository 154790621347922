// eslint-disable-next-line import/prefer-default-export
export const confidenceInPercentage = (value: number) => `${(value * 100).toFixed(2)}%`

export const dateFormatter = new Intl.DateTimeFormat('en-Gb', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
})
