/* eslint-disable camelcase */
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../store/configureStore'
import { confidenceInPercentage, dateFormatter } from '../../utils/formatters'
import { statusForJob } from '../../utils/statusForJob'

const JobInformations = () => {
  const job = useSelector((state: GlobalState) => state.jobs.currentJob)

  return (
    <Container>
      <Typography variant="body2">
        ID: <b>{job?.id}</b>
      </Typography>
      <Typography variant="body2">
        Date: <b>{job && dateFormatter.format(new Date(job.date_created))}</b>
      </Typography>
      <Typography variant="body2">
        Value: <b>{job?.result.value}</b>
      </Typography>
      <Typography variant="body2">
        Confidence: <b>{job?.result && confidenceInPercentage(job.result.confidence)}</b>
      </Typography>
      <Typography variant="body2">
        Status: <b>{job?.result && statusForJob(job.status, job.result.fail_reason)}</b>
      </Typography>
    </Container>
  )
}

export default JobInformations
