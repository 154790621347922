import React from 'react'

import { i18n } from '../../utils/i18n'

interface Props {
  text: string
}

const I18N = (props: Props) => {
  const { text } = props
  return <>{i18n(text)}</>
}

export default I18N
