import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MailIcon from '@material-ui/icons/Mail'
import React from 'react'
import { useSelector } from 'react-redux'

import I18N from '../../components/I18N/I18N'
import { GlobalState } from '../../store/configureStore'

const useStyles = makeStyles((t: Theme) => ({
  detail: {
    display: 'flex',
    '&> p': {
      marginLeft: t.spacing(2),
    },
  },
}))

const UserDetail = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const user = useSelector((state: GlobalState) => state.user.user)
  return (
    <>
      <Typography variant="h5" gutterBottom>
        <I18N text="User details" />
      </Typography>
      <div className={classes.detail}>
        <MailIcon />
        <Typography variant="body1" gutterBottom>
          {user?.email}
        </Typography>
      </div>
    </>
  )
}

export default UserDetail
