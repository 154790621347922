/* eslint-disable camelcase */
import * as Auth from '@frontends/account'
import { Rectangle } from '@frontends/common'

import { i18n } from './utils/i18n'

export interface User extends Auth.User {}

export interface StatusMessages extends Auth.StatusMessages {
  changePassword: Auth.Message
}

export enum JobStatus {
  archived = 'archived',
  pending = 'pending',
  success = 'success',
}

export enum JobType {
  odometer = 'odometer',
  vin = 'vin',
  licence_plate = 'licence_plate',
}

export const JobTypes = [JobType.odometer, JobType.licence_plate, JobType.vin]

export type Report = {
  year: number
  month: number
  total: number | undefined
}

export type Result = {
  confidence: number
  coordinates: Rectangle | null
  execution_time: number
  value: number
  fail_reason?: FailReason | null
}

export type Job = {
  id: string
  callback_url: string | null
  date_created: string
  type: JobType
  status: JobStatus
  result: Result
  annotation_submitted: boolean
  image?: string
}

export type Jobs = {
  count: number
  next: string | null
  previous: string | null
  results: Job[]
}

export enum FailReason {
  unreadable = 'unreadable',
  bad_type = 'bad_type',
  bad_rotation = 'bad_rotation',
}

export const FAIL_REASON_TEXTS = {
  [FailReason.unreadable]: i18n('unreadable'),
  [FailReason.bad_type]: i18n('bad type'),
  [FailReason.bad_rotation]: i18n('bad rotation'),
}

export type JobData = { fail_reason?: FailReason | null; value?: number; coordinates?: Rectangle }

export type Option = {
  name: string
  action: () => any
}
