/* eslint-disable camelcase */
import { emptyStatusMessage, isError, isSuccess } from '@frontends/account'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import I18N from '../../components/I18N/I18N'
import { GlobalState } from '../../store/configureStore'
import { changePassword, clearStatuses } from '../../store/user/actions'
import { i18n } from '../../utils/i18n'

const useStyles = makeStyles((t: Theme) => ({
  error: {
    color: t.palette.error.main,
  },
  success: {
    color: t.palette.success.main,
  },
  submit: {
    margin: t.spacing(3, 0, 2),
  },
  root: {
    display: 'flex',
  },
}))

const ChangePassword = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()

  const changePasswordStatus = useSelector((state: GlobalState) => state.user.statusMessages.changePassword)

  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState<string>('')
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<string>('')
  const [newPassword2ErrorMessage, setNewPassword2ErrorMessage] = useState<string>('')

  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPassword2, setNewPassword2] = useState<string>('')

  const submit = (event: React.FormEvent) => {
    event.preventDefault()
    if (oldPassword.length > 0 && newPassword.length > 0 && newPassword === newPassword2)
      dispatch(changePassword(oldPassword, newPassword))
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        <I18N text="Change password" />
      </Typography>
      <form className={classes.root} onSubmit={submit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControl
              error={oldPasswordErrorMessage.length > 0 || changePasswordStatus?.error?.current_password != null}
              margin="normal"
              size="small"
            >
              <InputLabel htmlFor="oldPassword">{i18n('Old password')}</InputLabel>
              <Input
                id="oldPassword"
                name="oldPassword"
                type="password"
                required
                value={oldPassword}
                onChange={(event) => {
                  setOldPasswordErrorMessage(event.target.value.length === 0 ? 'This field is required' : '')
                  setOldPassword(event.target.value)
                  if (changePasswordStatus !== emptyStatusMessage) {
                    dispatch(clearStatuses())
                  }
                }}
              />
              {(oldPasswordErrorMessage.length > 0 || changePasswordStatus?.error?.current_password != null) && (
                <FormHelperText id="oldPassword-text">
                  {oldPasswordErrorMessage ||
                    (changePasswordStatus.error?.current_password && changePasswordStatus?.error?.current_password[0])}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl
              error={newPasswordErrorMessage.length > 0 || changePasswordStatus?.error?.new_password != null}
              margin="normal"
              size="small"
            >
              <InputLabel htmlFor="newPassword">{i18n('New password')}</InputLabel>
              <Input
                id="newPassword"
                name="newPassword"
                type="password"
                required
                value={newPassword}
                onChange={(event) => {
                  if (newPassword2 !== event.target.value && newPassword2.length > 0) {
                    setNewPassword2ErrorMessage('Your new password and confirmation password do not match.')
                  } else {
                    setNewPasswordErrorMessage(event.target.value.length === 0 ? 'This field is required' : '')
                    if (newPassword2ErrorMessage === 'Your new password and confirmation password do not match.') {
                      setNewPassword2ErrorMessage('')
                    }
                  }
                  setNewPassword(event.target.value)
                  if (changePasswordStatus !== emptyStatusMessage) {
                    dispatch(clearStatuses())
                  }
                }}
              />
              {(newPasswordErrorMessage.length > 0 || changePasswordStatus?.error?.new_password != null) && (
                <FormHelperText id="newPassword-text">
                  {newPasswordErrorMessage ||
                    (changePasswordStatus?.error?.new_password && changePasswordStatus?.error?.new_password[0])}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl error={newPassword2ErrorMessage.length > 0} margin="normal" size="small">
              <InputLabel htmlFor="newPassword2">{i18n('Confirm password')}</InputLabel>
              <Input
                id="newPassword2"
                name="newPassword2"
                type="password"
                required
                value={newPassword2}
                onChange={(event) => {
                  if (newPassword !== event.target.value && newPassword.length > 0) {
                    setNewPassword2ErrorMessage('Your new password and confirmation password do not match.')
                  } else {
                    setNewPassword2ErrorMessage(event.target.value.length === 0 ? 'This field is required' : '')
                    if (newPasswordErrorMessage === 'Your new password and confirmation password do not match.') {
                      setNewPasswordErrorMessage('')
                    }
                  }
                  setNewPassword2(event.target.value)
                  if (changePasswordStatus !== emptyStatusMessage) {
                    dispatch(clearStatuses())
                  }
                }}
              />
              {newPassword2ErrorMessage.length > 0 && (
                <FormHelperText id="newPassword2-text">{newPassword2ErrorMessage}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {isError(changePasswordStatus) && (
            <Typography variant="body1" gutterBottom className={classes.error}>
              {changePasswordStatus.error?.detail}
            </Typography>
          )}
          {isSuccess(changePasswordStatus) && (
            <Typography variant="body1" gutterBottom className={classes.success}>
              {changePasswordStatus.success}
            </Typography>
          )}
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={oldPassword.length === 0 || newPassword.length === 0 || newPassword !== newPassword2}
            >
              <I18N text="Change password" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ChangePassword
