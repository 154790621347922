import { StatusMessages, User } from '../../types'

export interface UserState {
  user: User | null
  isLogging: boolean
  isLogged: boolean
  isRefreshingToken: boolean
  statusMessages: StatusMessages
}

export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED'
export const USER_LOGIN_ERRORED = 'USER_LOGIN_ERRORED'
export const USER_LOGIN_FINISHED = 'USER_LOGIN_FINISHED'
export const USER_CHANGE_PASSWORD_STARTED = 'USER_CHANGE_PASSWORD_STARTED'
export const USER_CHANGE_PASSWORD_ERRORED = 'USER_CHANGE_PASSWORD_ERRORED'
export const USER_CHANGE_PASSWORD_FINISHED = 'USER_CHANGE_PASSWORD_FINISHED'
export const USER_RESET_PASSWORD_STARTED = 'USER_RESET_PASSWORD_STARTED'
export const USER_RESET_PASSWORD_ERRORED = 'USER_RESET_PASSWORD_ERRORED'
export const USER_RESET_PASSWORD_FINISHED = 'USER_RESET_PASSWORD_FINISHED'
export const USER_FORGOTTEN_PASSWORD_STARTED = 'USER_FORGOTTEN_PASSWORD_STARTED'
export const USER_FORGOTTEN_PASSWORD_ERRORED = 'USER_FORGOTTEN_PASSWORD_ERRORED'
export const USER_FORGOTTEN_PASSWORD_FINISHED = 'USER_FORGOTTEN_PASSWORD_FINISHED'
export const USER_REFRESH_TOKEN_STARTED = 'USER_REFRESH_TOKEN_STARTED'
export const USER_REFRESH_TOKEN_ERRORED = 'USER_REFRESH_TOKEN_ERRORED'
export const USER_REFRESH_TOKEN_FINISHED = 'USER_REFRESH_TOKEN_FINISHED'
export const USER_CLEAR_STATUSES = 'USER_CLEAR_STATUSES'
export const USER_LOGOUT = 'USER_LOGOUT'

interface LoginStarted {
  type: typeof USER_LOGIN_STARTED
}

interface LoginErrored {
  type: typeof USER_LOGIN_ERRORED
  payload: {
    error: any
  }
}

interface LoginFinished {
  type: typeof USER_LOGIN_FINISHED
  payload: {
    user: User
  }
}

interface ChangePasswordStarted {
  type: typeof USER_CHANGE_PASSWORD_STARTED
}

interface ChangePasswordErrored {
  type: typeof USER_CHANGE_PASSWORD_ERRORED
  payload: {
    error: any
  }
}

interface ChangePasswordFinished {
  type: typeof USER_CHANGE_PASSWORD_FINISHED
  payload: {
    success: string
  }
}

interface ResetPasswordStarted {
  type: typeof USER_RESET_PASSWORD_STARTED
}

interface ResetPasswordErrored {
  type: typeof USER_RESET_PASSWORD_ERRORED
  payload: {
    error: any
  }
}

interface ResetPasswordFinished {
  type: typeof USER_RESET_PASSWORD_FINISHED
  payload: {
    success: string
  }
}

interface ForgottenPasswordStarted {
  type: typeof USER_FORGOTTEN_PASSWORD_STARTED
}

interface ForgottenPasswordErrored {
  type: typeof USER_FORGOTTEN_PASSWORD_ERRORED
  payload: {
    error: any
  }
}

interface ForgottenPasswordFinished {
  type: typeof USER_FORGOTTEN_PASSWORD_FINISHED
  payload: {
    success: string
  }
}

interface Logout {
  type: typeof USER_LOGOUT
}

interface ClearStatuses {
  type: typeof USER_CLEAR_STATUSES
}

interface RefreshTokenStarted {
  type: typeof USER_REFRESH_TOKEN_STARTED
}

interface RefreshTokenErrored {
  type: typeof USER_REFRESH_TOKEN_ERRORED
}

interface RefreshTokenFinished {
  type: typeof USER_REFRESH_TOKEN_FINISHED
}

export type UserTypes =
  | LoginStarted
  | LoginErrored
  | LoginFinished
  | ChangePasswordStarted
  | ChangePasswordErrored
  | ChangePasswordFinished
  | ResetPasswordStarted
  | ResetPasswordErrored
  | ResetPasswordFinished
  | Logout
  | ClearStatuses
  | RefreshTokenStarted
  | RefreshTokenErrored
  | RefreshTokenFinished
  | ForgottenPasswordStarted
  | ForgottenPasswordErrored
  | ForgottenPasswordFinished
