import {
  JOBS_CLEAN,
  JOBS_LOAD_JOB_ERRORED,
  JOBS_LOAD_JOB_FINISHED,
  JOBS_LOAD_JOB_STARTED,
  JOBS_LOAD_JOBS_ERRORED,
  JOBS_LOAD_JOBS_FINISHED,
  JOBS_LOAD_JOBS_STARTED,
  JOBS_SAVE_JOB_ERRORED,
  JOBS_SAVE_JOB_FINISHED,
  JOBS_SAVE_JOB_STARTED,
  JobsState,
  JobsTypes,
} from './types'

export const initialState: JobsState = {
  currentJob: null,
  jobs: null,
  isLoadingJob: false,
  isLoadingJobs: false,
  isWrongJobRequst: false,
  isSavingJob: false,
}

const jobsReducer = (state = initialState, action: JobsTypes): JobsState => {
  switch (action.type) {
    case JOBS_LOAD_JOBS_STARTED:
      return {
        ...state,
        isLoadingJobs: true,
        isWrongJobRequst: false,
      }
    case JOBS_LOAD_JOBS_FINISHED:
      return {
        ...state,
        jobs: action.payload.jobs,
        isLoadingJobs: false,
      }
    case JOBS_LOAD_JOBS_ERRORED:
      return {
        ...state,
        isLoadingJobs: false,
      }
    case JOBS_LOAD_JOB_STARTED:
      return {
        ...state,
        isLoadingJob: true,
        isWrongJobRequst: false,
      }
    case JOBS_LOAD_JOB_FINISHED:
      return {
        ...state,
        currentJob: action.payload.job,
        isLoadingJob: false,
      }
    case JOBS_LOAD_JOB_ERRORED:
      return {
        ...state,
        isLoadingJob: false,
        isWrongJobRequst: action.payload.isWrongJobRequst,
      }
    case JOBS_SAVE_JOB_STARTED:
      return {
        ...state,
        isSavingJob: true,
      }
    case JOBS_SAVE_JOB_ERRORED:
      return {
        ...state,
        isSavingJob: false,
      }
    case JOBS_SAVE_JOB_FINISHED:
      return {
        ...state,
        isSavingJob: false,
      }
    case JOBS_CLEAN:
      return {
        ...state,
        jobs: null,
        currentJob: null,
      }
    default:
      return state
  }
}

export default jobsReducer
