import { Account } from '@frontends/account'
import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import Footer from '../../containers/Footer/Footer'
import { GlobalState } from '../../store/configureStore'
import { clearStatuses, forgottenPassword, login, resetPassword } from '../../store/user/actions'
import { theme } from '../../utils/theme'

const NotLoggedRouter = (): ReactElement => {
  const dispatch = useDispatch()
  const statusMessages = useSelector((state: GlobalState) => state.user.statusMessages)

  return (
    <Switch>
      <Route path="/user">
        <Account
          theme={theme}
          name="Odocap"
          functions={{
            login: (email: string, password: string) => dispatch(login(email, password)),
            reset: (password: string, uid: string, token: string) => dispatch(resetPassword(password, uid, token)),
            forgotten: (email: string) => dispatch(forgottenPassword(email)),
            clear: () => dispatch(clearStatuses()),
          }}
          statusMessages={statusMessages}
          footer={<Footer />}
        />
      </Route>
      <Redirect to="/user" />
    </Switch>
  )
}

export default NotLoggedRouter
