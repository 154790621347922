/* eslint-disable camelcase */
import { ThunkDispatch } from 'redux-thunk'

import { JobData } from '../../types'
import axios from '../../utils/configuredAxios'
import { readToken } from '../../utils/readToken'
import { redirectTo } from '../application/actions'
import { GlobalState } from '../configureStore'
import { getNewAccessToken } from '../user/actions'
import {
  JOBS_CLEAN,
  JOBS_LOAD_JOB_ERRORED,
  JOBS_LOAD_JOB_FINISHED,
  JOBS_LOAD_JOB_STARTED,
  JOBS_LOAD_JOBS_ERRORED,
  JOBS_LOAD_JOBS_FINISHED,
  JOBS_LOAD_JOBS_STARTED,
  JOBS_SAVE_JOB_ERRORED,
  JOBS_SAVE_JOB_FINISHED,
  JOBS_SAVE_JOB_STARTED,
  JobsState,
  JobsTypes,
} from './types'

const getJobs = (page: number, type: string, fromDate: Date | null, toDate: Date | null, uuidSearch: string) => {
  return async (dispatch: ThunkDispatch<JobsState, null, JobsTypes>) => {
    dispatch({
      type: JOBS_LOAD_JOBS_STARTED,
    })
    try {
      const jobsRequest = await axios.get(
        `/api/f/jobs/?page=${page}&type=${type}${fromDate && `&date_after=${fromDate.toISOString()}`}${
          toDate && `&date_before=${toDate.toISOString()}${uuidSearch.length > 0 ? `&search=${uuidSearch}` : ''}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${readToken()}`,
          },
        },
      )
      dispatch({
        type: JOBS_LOAD_JOBS_FINISHED,
        payload: {
          jobs: jobsRequest.data,
        },
      })
    } catch (e) {
      if (e.response && e.response.status === 401) {
        dispatch(getNewAccessToken())
      }
      dispatch({
        type: JOBS_LOAD_JOBS_ERRORED,
      })
    }
  }
}

const getJob = (id: string) => {
  return async (dispatch: ThunkDispatch<JobsState, null, JobsTypes>) => {
    dispatch({
      type: JOBS_LOAD_JOB_STARTED,
    })
    try {
      const jobRequest = await axios.get(`/api/f/jobs/${id}/`, {
        headers: {
          Authorization: `Bearer ${readToken()}`,
        },
      })
      dispatch({
        type: JOBS_LOAD_JOB_FINISHED,
        payload: {
          job: jobRequest.data,
        },
      })
    } catch (e) {
      if (e.response && e.response.status === 401) {
        dispatch(getNewAccessToken())
      }
      dispatch({
        type: JOBS_LOAD_JOB_ERRORED,
        payload: {
          isWrongJobRequst: e.response && (e.response.status === 404 || e.response.status === 500),
        },
      })
    }
  }
}

const nextJob = (id: string) => {
  return async (dispatch: ThunkDispatch<JobsState, null, JobsTypes>, getState: () => GlobalState) => {
    const state = getState()
    const { jobs } = state.jobs
    if (jobs) {
      const currentIDIndex = jobs.results.findIndex((j) => j.id === id)
      if (currentIDIndex === -1 || currentIDIndex + 1 >= jobs.results.length) {
        dispatch(redirectTo('/annotator/'))
      } else {
        dispatch(redirectTo(`/annotator/${jobs.results[currentIDIndex + 1].id}/`))
      }
    } else {
      dispatch(redirectTo('/annotator/'))
    }
  }
}

const prevJob = (id: string) => {
  return async (dispatch: ThunkDispatch<JobsState, null, JobsTypes>, getState: () => GlobalState) => {
    const state = getState()
    const { jobs } = state.jobs
    if (jobs) {
      const currentIDIndex = jobs.results.findIndex((j) => j.id === id)
      if (currentIDIndex - 1 < 0) {
        dispatch(redirectTo('/annotator/'))
      } else {
        dispatch(redirectTo(`/annotator/${jobs.results[currentIDIndex - 1].id}/`))
      }
    } else {
      dispatch(redirectTo('/annotator/'))
    }
  }
}

const saveJob = (id: string, jobData: JobData) => {
  return async (dispatch: ThunkDispatch<JobsState, null, any>) => {
    dispatch({
      type: JOBS_SAVE_JOB_STARTED,
    })
    try {
      await axios.patch(
        `/api/f/jobs/${id}/annotation/`,
        { result: jobData },
        {
          headers: {
            Authorization: `Bearer ${readToken()}`,
          },
        },
      )
      dispatch(nextJob(id))
      dispatch({
        type: JOBS_SAVE_JOB_FINISHED,
      })
    } catch (e) {
      if (e.response && e.response.status === 401) {
        dispatch(getNewAccessToken())
      }
      dispatch({
        type: JOBS_SAVE_JOB_ERRORED,
        payload: {
          isWrongJobRequst: e.response && (e.response.status === 404 || e.response.status === 500),
        },
      })
    }
  }
}

const cleanJobs = () => {
  return async (dispatch: ThunkDispatch<JobsState, null, JobsTypes>) => {
    dispatch({
      type: JOBS_CLEAN,
    })
  }
}

export { cleanJobs, getJob, getJobs, nextJob, prevJob, saveJob }
