import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { logout } from '../../store/user/actions'
import { i18n } from '../../utils/i18n'
import I18N from '../I18N/I18N'

const useStyles = makeStyles((t: Theme) => ({
  white: {
    color: '#ffffff',
  },
}))

const HeaderMenu = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <nav>
      <Button
        className={classes.white}
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ExpandMore />}
      >
        <I18N text="My Account" />
      </Button>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          component={NavLink}
          to="/user"
          onClick={(_e: React.MouseEvent) => {
            handleClose()
          }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          {i18n('Profile')}
        </MenuItem>
        <Divider light />
        <MenuItem
          onClick={(_e) => {
            handleClose()
            dispatch(logout())
          }}
        >
          <ListItemIcon>
            <Icon>logout</Icon>
          </ListItemIcon>
          {i18n('Logout')}
        </MenuItem>
      </Menu>
    </nav>
  )
}

export default HeaderMenu
