import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Helmet } from 'react-helmet'

import I18N from '../../components/I18N/I18N'
import { i18n } from '../../utils/i18n'

interface Props {
  name: string
}

const useStyles = makeStyles((t: Theme) => ({
  divider: {
    marginTop: t.spacing(4),
    marginBottom: t.spacing(4),
  },
}))

const PageHeader = (props: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const { name } = props

  return (
    <>
      <Helmet>
        <title>{i18n(`Odocap | ${name}`)}</title>
      </Helmet>
      <Typography variant="h5" gutterBottom component="h3">
        <I18N text={name} />
      </Typography>
      <Divider className={classes.divider} />
    </>
  )
}

export default PageHeader
