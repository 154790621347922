import { createMuiTheme } from '@material-ui/core'

// eslint-disable-next-line import/prefer-default-export
export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#47C8FF',
      main: '#40B4E5',
      dark: '#237699',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FF4766',
      main: '#E6405B',
      dark: '#991C30',
      contrastText: '#fff',
    },
  },
})
