import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from 'react-redux'

import I18N from '../../components/I18N/I18N'
import StyledTableCell from '../../components/StyledTableCell/StyledTableCell'
import StyledTableRow from '../../components/StyledTableRow/StyledTableRow'
import { GlobalState } from '../../store/configureStore'
import { i18n } from '../../utils/i18n'

const useStyles = makeStyles((t: Theme) => ({
  noData: {
    padding: '1rem',
    textAlign: 'center',
  },
}))

const DataTable = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const report = useSelector((state: GlobalState) => state.report.report)
  const isLoadingReport = useSelector((state: GlobalState) => state.report.isLoadingReport)

  return report && report.length > 0 ? (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="report table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">
              <I18N text={i18n('Year')} />
            </StyledTableCell>
            <StyledTableCell align="center">
              <I18N text={i18n('Month')} />
            </StyledTableCell>
            <StyledTableCell align="right">
              <I18N text={i18n('Total')} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {report.map((row) => (
            <StyledTableRow key={`${row.year}${row.month}`}>
              <StyledTableCell align="left">{row.year}</StyledTableCell>
              <StyledTableCell align="center">{`0${row.month}`.slice(-2)}</StyledTableCell>
              <StyledTableCell align="right">{row.total}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Paper className={classes.noData}>
      {isLoadingReport ? (
        <CircularProgress />
      ) : (
        <Typography variant="body2" component="span" className={classes.noData}>
          <I18N text="No data" />
        </Typography>
      )}
    </Paper>
  )
}

export default DataTable
