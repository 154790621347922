import Grid from '@material-ui/core/Grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DataChart from '../../containers/DataChart/DataChart'
import DataTable from '../../containers/DataTable/DataTable'
import PageHeader from '../../containers/PageHeader/PageHeader'
import { GlobalState } from '../../store/configureStore'
import { getReport } from '../../store/report/actions'

const Dashboard = () => {
  const dispatch = useDispatch()

  const isRefreshingToken = useSelector((state: GlobalState) => state.user.isRefreshingToken)
  const isLoadingReport = useSelector((state: GlobalState) => state.report.isLoadingReport)
  const report = useSelector((state: GlobalState) => state.report.report)

  useEffect(() => {
    if (report === null && !isRefreshingToken && !isLoadingReport) {
      dispatch(getReport())
    }
  }, [dispatch, isRefreshingToken, isLoadingReport, report])

  return (
    <>
      <PageHeader name="Dashboard" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <DataTable />
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <DataChart />
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
