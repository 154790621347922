export interface ApplicationState {
  isDrawerOpened: boolean
  tab: number
  page: number
  fromDate: Date | null
  toDate: Date | null
  uuidSearch: string
  goTo: string | null
}

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const SET_TAB = 'SET_TAB'
export const SET_PAGE = 'SET_PAGE'
export const SET_FROM_DATE = 'SET_FROM_DATE'
export const SET_TO_DATE = 'SET_TO_DATE'
export const SET_UUID_SEARCH = 'SET_UUID_SEARCH'
export const GO_TO = 'GO_TO'

interface ToggleDrawer {
  type: typeof TOGGLE_DRAWER
}

interface SetTab {
  type: typeof SET_TAB
  payload: {
    tab: number
  }
}

interface SetPage {
  type: typeof SET_PAGE
  payload: {
    page: number
  }
}

interface SetFromDate {
  type: typeof SET_FROM_DATE
  payload: {
    fromDate: Date | null
  }
}

interface SetToDate {
  type: typeof SET_TO_DATE
  payload: {
    toDate: Date | null
  }
}

interface SetUuidSearch {
  type: typeof SET_UUID_SEARCH
  payload: {
    uuidSearch: string
  }
}

interface GoTo {
  type: typeof GO_TO
  payload: {
    goto: string | null
  }
}

export type ApplicationTypes = ToggleDrawer | SetTab | SetPage | SetFromDate | SetToDate | SetUuidSearch | GoTo
