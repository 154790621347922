import { Rectangle } from '@frontends/common'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import I18N from '../../components/I18N/I18N'
import SplitButton from '../../components/SplitButton/SplitButton'
import PageHeader from '../../containers/PageHeader/PageHeader'
import { GlobalState } from '../../store/configureStore'
import { getJob, nextJob, prevJob, saveJob } from '../../store/jobs/actions'
import { FailReason } from '../../types'
import { i18n } from '../../utils/i18n'
import ImageContainer from './ImageContainer'
import JobInformations from './JobInformations'
import JobReminders from './JobReminders'

interface ParamTypes {
  id: string
}

const useStyles = makeStyles((t: Theme) => ({
  container: {
    paddingTop: t.spacing(4),
    paddingBottom: t.spacing(4),
  },
  paper: {
    padding: t.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: t.spacing(1),
  },
  submit: {
    margin: t.spacing(3, 0, 2),
  },
}))

const Annotator = () => {
  const { id } = useParams<ParamTypes>()
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const history = useHistory()

  const isRefreshingToken = useSelector((state: GlobalState) => state.user.isRefreshingToken)
  const isLoadingJob = useSelector((state: GlobalState) => state.jobs.isLoadingJob)
  const isSavingJob = useSelector((state: GlobalState) => state.jobs.isSavingJob)
  const isWrongJobRequst = useSelector((state: GlobalState) => state.jobs.isWrongJobRequst)
  const job = useSelector((state: GlobalState) => state.jobs.currentJob)

  const [rectangle, setRectangle] = useState<Rectangle | null>(job && job.result && job.result.coordinates)

  const [value, setValue] = useState<number>(job && job.result ? job.result.value : 0)

  useEffect(() => {
    if (!isRefreshingToken) {
      dispatch(getJob(id))
    }
  }, [dispatch, id, isRefreshingToken])

  useEffect(() => {
    if (isWrongJobRequst || (job !== null && job.result == null)) {
      history.push('/annotator/')
    }
  }, [isWrongJobRequst, history, job])

  useEffect(() => {
    setRectangle(job && job.result ? job.result.coordinates : null)
    setValue(job && job.result ? job.result.value : 0)
  }, [job])

  return (
    <>
      <PageHeader name="Annotator" />
      {!isLoadingJob && job !== null && job.result !== null && (
        <Grid container>
          <Grid item xs={12} sm={8} md={10}>
            {job.image && <ImageContainer image={job.image} coordinates={rectangle} save={setRectangle} />}
          </Grid>
          <Grid item xs={12} sm={4} md={2} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                <I18N text="Odometer" />
              </Typography>
              <JobInformations />
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="value"
                  label="Value"
                  name="value"
                  autoFocus
                  value={value}
                  type="numeric"
                  onChange={(event) =>
                    setValue(
                      Number.isNaN(Number.parseFloat(event.target.value)) ? 0 : Number.parseFloat(event.target.value),
                    )
                  }
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={rectangle == null || isSavingJob}
                  onClick={() => {
                    if (rectangle != null) {
                      dispatch(saveJob(id, { value, coordinates: rectangle }))
                    }
                  }}
                >
                  <I18N text="Save" />
                </Button>
                <SplitButton
                  options={[
                    {
                      name: i18n('Could not read'),
                      action: () => dispatch(saveJob(id, { fail_reason: FailReason.unreadable })),
                    },
                    {
                      name: i18n('Not an odometer'),
                      action: () => dispatch(saveJob(id, { fail_reason: FailReason.bad_type })),
                    },
                    {
                      name: i18n('Bad rotation'),
                      action: () => dispatch(saveJob(id, { fail_reason: FailReason.bad_rotation })),
                    },
                  ]}
                />
              </form>
              <JobReminders />

              <ButtonGroup disableElevation variant="contained" color="primary" aria-label="navigatiion buttons">
                <Button onClick={() => dispatch(prevJob(id))}>Prev</Button>
                <Button onClick={() => dispatch(nextJob(id))}>Next</Button>
              </ButtonGroup>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Annotator
