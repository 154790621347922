/* eslint-disable camelcase */
import { ThunkDispatch } from 'redux-thunk'

import axios from '../../utils/configuredAxios'
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/localStorageManager'
import { readToken } from '../../utils/readToken'
import { cleanJobs } from '../jobs/actions'
import { cleanReport } from '../report/actions'
import { ReportTypes } from '../report/types'
import {
  USER_CHANGE_PASSWORD_ERRORED,
  USER_CHANGE_PASSWORD_FINISHED,
  USER_CHANGE_PASSWORD_STARTED,
  USER_CLEAR_STATUSES,
  USER_FORGOTTEN_PASSWORD_ERRORED,
  USER_FORGOTTEN_PASSWORD_FINISHED,
  USER_FORGOTTEN_PASSWORD_STARTED,
  USER_LOGIN_ERRORED,
  USER_LOGIN_FINISHED,
  USER_LOGIN_STARTED,
  USER_LOGOUT,
  USER_REFRESH_TOKEN_ERRORED,
  USER_REFRESH_TOKEN_FINISHED,
  USER_REFRESH_TOKEN_STARTED,
  USER_RESET_PASSWORD_ERRORED,
  USER_RESET_PASSWORD_FINISHED,
  USER_RESET_PASSWORD_STARTED,
  UserState,
  UserTypes,
} from './types'

const logout = () => {
  return async (dispatch: ThunkDispatch<UserState, null, UserTypes | ReportTypes>) => {
    saveToLocalStorage('access', '')
    saveToLocalStorage('refresh', '')
    dispatch(cleanReport())
    dispatch(cleanJobs())
    dispatch({
      type: USER_LOGOUT,
    })
  }
}

const getNewAccessToken = () => {
  return async (dispatch: ThunkDispatch<UserState, null, UserTypes>) => {
    dispatch({
      type: USER_REFRESH_TOKEN_STARTED,
    })
    try {
      const refreshTokenRequest = await axios.post('/api/f/auth/jwt/refresh/', {
        refresh: getFromLocalStorage('refresh', ''),
      })
      saveToLocalStorage('access', refreshTokenRequest.data.access)
      dispatch({
        type: USER_REFRESH_TOKEN_FINISHED,
      })
    } catch {
      dispatch(logout())
      dispatch({
        type: USER_REFRESH_TOKEN_ERRORED,
      })
    }
  }
}

const login = (email: string, password: string) => {
  return async (dispatch: ThunkDispatch<UserState, null, UserTypes>) => {
    dispatch({
      type: USER_LOGIN_STARTED,
    })
    try {
      const userRequest = await axios.post('/api/f/auth/jwt/create/', {
        email,
        password,
      })
      const { access, refresh } = userRequest.data
      saveToLocalStorage('access', access)
      saveToLocalStorage('refresh', refresh)
      dispatch({
        type: USER_LOGIN_FINISHED,
        payload: {
          user: JSON.parse(atob(access.split('.')[1])).user_details,
        },
      })
    } catch (error) {
      dispatch({
        type: USER_LOGIN_ERRORED,
        payload: {
          error: error.response ? error.response.data : { detail: 'Fatal error' },
        },
      })
    }
  }
}

const changePassword = (current_password: string, new_password: string) => {
  return async (dispatch: ThunkDispatch<UserState, null, UserTypes>) => {
    dispatch({
      type: USER_CHANGE_PASSWORD_STARTED,
    })
    try {
      await axios.post(
        '/api/f/auth/users/set_password/',
        {
          current_password,
          new_password,
        },
        {
          headers: {
            Authorization: `Bearer ${readToken()}`,
          },
        },
      )
      dispatch({
        type: USER_CHANGE_PASSWORD_FINISHED,
        payload: {
          success: `Password was changed`,
        },
      })
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(getNewAccessToken())
      }
      dispatch({
        type: USER_CHANGE_PASSWORD_ERRORED,
        payload: {
          error: error.response ? error.response.data : { detail: 'Fatal error' },
        },
      })
    }
  }
}

const forgottenPassword = (email: string) => {
  return async (dispatch: ThunkDispatch<UserState, null, UserTypes>) => {
    dispatch({
      type: USER_FORGOTTEN_PASSWORD_STARTED,
    })
    try {
      await axios.post('/api/f/auth/users/reset_password/', {
        email,
      })
      dispatch({
        type: USER_FORGOTTEN_PASSWORD_FINISHED,
        payload: {
          success: `Email with reset link was sent to: ${email}`,
        },
      })
    } catch (error) {
      dispatch({
        type: USER_FORGOTTEN_PASSWORD_ERRORED,
        payload: {
          error: error.response ? error.response.data : { detail: 'Fatal error' },
        },
      })
    }
  }
}

const resetPassword = (new_password: string, uid: string, token: string) => {
  return async (dispatch: ThunkDispatch<UserState, null, UserTypes>) => {
    dispatch({
      type: USER_RESET_PASSWORD_STARTED,
    })
    try {
      await axios.post('/api/f/auth/users/reset_password_confirm/', {
        new_password,
        uid,
        token,
      })
      dispatch({
        type: USER_RESET_PASSWORD_FINISHED,
        payload: {
          success: `Password was changed`,
        },
      })
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(getNewAccessToken())
      }
      dispatch({
        type: USER_RESET_PASSWORD_ERRORED,
        payload: {
          error: error.response ? error.response.data : { detail: 'Fatal error' },
        },
      })
    }
  }
}

const clearStatuses = () => {
  return async (dispatch: ThunkDispatch<UserState, null, UserTypes | ReportTypes>) => {
    dispatch({
      type: USER_CLEAR_STATUSES,
    })
  }
}

export { changePassword, clearStatuses, forgottenPassword, getNewAccessToken, login, logout, resetPassword }
