import { Rectangle, Size } from '@frontends/common'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import ImageCanvas from './ImageCanvas'

interface Props {
  image: string
  coordinates: Rectangle | null
  save: React.Dispatch<any>
}

const useStyles = makeStyles((t: Theme) => ({
  imageContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& > img': {
      display: 'block',
      objectFit: 'contain',
    },
  },
}))

const ImageContainer = (props: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const { image, coordinates, save } = props

  const [imageSize, setImageSize] = useState<Size>({ width: 0, height: 0 })

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height })
    }
    img.src = image
    return () => {
      img.src = ''
    }
  }, [image, setImageSize])

  return (
    <div className={classes.imageContainer}>
      <img src={image} alt="odometer" />
      <ImageCanvas coordinates={coordinates} imageSize={imageSize} save={save} />
    </div>
  )
}

export default ImageContainer
