/* eslint-disable camelcase */
import Container from '@material-ui/core/Container'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../store/configureStore'
import { FAIL_REASON_TEXTS } from '../../types'

const useStyles = makeStyles((t: Theme) => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  reminders: {
    marginTop: t.spacing(1),
  },
}))

const JobReminders = () => {
  const job = useSelector((state: GlobalState) => state.jobs.currentJob)

  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Container className={classes.reminders}>
      {job?.annotation_submitted === true && (
        <Typography variant="body2" gutterBottom>
          <WarningIcon color="error" fontSize="small" /> Annotation has been already submitted for this image.
        </Typography>
      )}
      {job?.annotation_submitted === true && job.result.fail_reason && (
        <Typography variant="body2" gutterBottom>
          <WarningIcon color="error" fontSize="small" /> Reported issue:{' '}
          <b className={classes.uppercase}>{FAIL_REASON_TEXTS[job.result.fail_reason]}</b>
        </Typography>
      )}
    </Container>
  )
}

export default JobReminders
